import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FullSaleTransactionDataFragment,
    SaleTransactionStage,
    SalesTransactionActivityDataFragment,
} from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';

export type TransactionTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const TransactionTab = ({ saleTransaction }: TransactionTabProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const { lastActivity } = saleTransaction;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const useCardDesignForAccordion = !isSmall;

    const getAuthorName = useCallback((activity: SalesTransactionActivityDataFragment) => {
        switch (activity.__typename) {
            case 'CreationActivity':
            case 'BiddingActivity':
            case 'SaleUpdateActivity':
            case 'SaleStageUpdateActivity':
            case 'SaleBroadcastActivity':
            case 'SaleCommentActivity':
                return activity.author.fullName;
            default:
                return '-';
        }
    }, []);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.transactionDetail')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={saleTransaction.identifier}
                                label={t('saleTransactionsPage:transactionDetails.transactionNo')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    saleTransaction.stage === SaleTransactionStage.ForHandover
                                        ? 'Awarded'
                                        : saleTransaction.stage
                                }
                                label={t('saleTransactionsPage:transactionDetails.transactionStatus')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={t('common:formats.shortDate', { date: new Date(saleTransaction.createdAt) })}
                                label={t('saleTransactionsPage:transactionDetails.createdOn')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={t('common:formats.shortDate', { date: new Date(saleTransaction.updatedAt) })}
                                label={t('saleTransactionsPage:transactionDetails.lastUpdatedOn')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    saleTransaction.internalRemarks
                                        ? saleTransaction.internalRemarks
                                        : t('common:blankValue')
                                }
                                display="block"
                                label={t('saleTransactionsPage:transactionDetails.internalRemarks')}
                            />
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>

            <Grid xs={12} item>
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.activityLog')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    {lastActivity && (
                        <Grid spacing={1} container>
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={lastActivity.type}
                                    label={t('saleTransactionsPage:activity.action')}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={getAuthorName(lastActivity)}
                                    label={t('saleTransactionsPage:activity.updatedBy')}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={t('common:formats.shortDate', {
                                        date: new Date(lastActivity.date),
                                    })}
                                    label={t('saleTransactionsPage:activity.updatedOn')}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default TransactionTab;
